import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  TairtableConfig,
  TjassConfig,
  TjwExportConfig,
  TreaderConfig,
  TschalttoolConfig,
} from '../../../types/Tfeeds'

const schalttoolConfig = {}
const jassConfig = {}
const airtableConfig = {}
const otherConfig = {}
const debugConfig = {}
const readerConfig = {}
const jwExportConfig = {}
const authorizationConfigs = { username: '', password: '' }

const editFeedSlice = createSlice({
  name: 'editFeed',
  initialState: {
    schalttoolConfig,
    jassConfig,
    otherConfig,
    debugConfig,
    readerConfig,
    airtableConfig,
    authorizationConfigs,
    jwExportConfig,
  },
  reducers: {
    setSchalttoolConfig(state, action: PayloadAction<TschalttoolConfig>) {
      state.schalttoolConfig = action.payload
    },
    setReaderConfig(state, action: PayloadAction<TreaderConfig>) {
      state.readerConfig = action.payload
    },
    setJassConfig(state, action: PayloadAction<TjassConfig>) {
      state.jassConfig = action.payload
    },
    setAirtableConfig(state, action: PayloadAction<TairtableConfig>) {
      state.airtableConfig = action.payload
    },
    setJwExportConfig(state, action: PayloadAction<TjwExportConfig>) {
      state.jwExportConfig = action.payload
    },
    setOtherConfig(state, action: PayloadAction<string>) {
      state.otherConfig = action.payload
    },
    setDebugConfig(state, action: PayloadAction<string>) {
      state.debugConfig = action.payload
    },
    setAuthorizationConfigs(state, action: PayloadAction<any>) {
      state.authorizationConfigs = action.payload
    },
  },
})

export const {
  setSchalttoolConfig,
  setJassConfig,
  setOtherConfig,
  setDebugConfig,
  setReaderConfig,
  setAirtableConfig,
  setAuthorizationConfigs,
  setJwExportConfig,
} = editFeedSlice.actions
export default editFeedSlice.reducer
