import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useGetPublishersListQuery, useGetReadersListQuery } from '../../../api/api'
import { RootState } from '../../../redux/store'
import {
  Tauthorization,
  TauthorizationConfigs,
  Tfeed,
  ThtmlSource,
  TzipSource,
} from '../../../types/Tfeeds'

export const useAllHooks = () => {
  const {
    jassConfig,
    schalttoolConfig,
    debugConfig,
    otherConfig,
    readerConfig,
    airtableConfig,
    jwExportConfig,
  } = useSelector((state: RootState) => state.editFeed)
  const { data: readersList } = useGetReadersListQuery()
  const { data: publishersList } = useGetPublishersListQuery()

  const dispatch = useDispatch()
  const uniqueId = '55534'

  const [name, setName] = useState('name')
  const [url, setUrl] = useState('url')
  const [htmlSource, setHtmlSource] = useState<ThtmlSource>('url')
  const [ignoreUrlSourceHttpErrors, setIgnoreUrlSourceHttpErrors] = useState(false)
  const [zipSource, setZipSource] = useState<TzipSource>('create')
  const [ignoreZipSourceHttpErrors, setIgnoreZipSourceHttpErrors] = useState(false)
  const [template, setTemplate] = useState('')
  const [publisher, setPublisher] = useState('schalttool')
  const [defaultPublisher, setDefaultPublisher] = useState('')
  const [publisherConfig, setPublisherConfig] = useState()
  const [autoUnpublish, setAutoUnpublish] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [reader, setReader] = useState('generic-xml')
  const [serverResponse, setServerResponse] = useState({})
  const [schedule, setSchedule] = useState(['0 * * * *'])
  const [cronValue, setCronValue] = useState('0 * * * *')
  const [newFeedData, setNewFeedData] = useState<Tfeed>({})
  const [monthlySoftQuota, setMonthlySoftQuota] = useState<number>(1000)
  const [monthlyHardQuota, setMonthlyHardQuota] = useState<number>(1200)
  const [quotaThreshold, setQuotaThreshold] = useState<number>(90)
  const [recipientEmail, setRecipientEmail] = useState('')
  const [authorization, setAuthorization] = useState<Tauthorization>('none')
  const [authorizationConfigs, setAuthorizationConfigs] = useState<TauthorizationConfigs>({})
  const [republishExpired, setRepublishExpired] = useState(false)
  const [disableUpdates, setDisableUpdates] = useState(false)
  const [readJsonLdSchema, setReadJsonLdSchema] = useState(false)

  useEffect(() => {
    let publisherConfig

    switch (publisher) {
      case 'schalttool':
        publisherConfig = schalttoolConfig
        break
      case 'jass':
        publisherConfig = jassConfig
        break
      case 'airtable':
        publisherConfig = airtableConfig
        break
      case 'debug':
        publisherConfig = debugConfig
        break
      case 'jw-export':
        publisherConfig = jwExportConfig
        break
      default:
        publisherConfig = otherConfig
        break
    }

    const newFeedObject: Tfeed = {
      name,
      reader,
      readerConfig,
      publisher,
      publisherConfig,
      autoUnpublish,
      isActive,
      url,
      htmlSource,
      ignoreUrlSourceHttpErrors,
      ignoreZipSourceHttpErrors,
      zipSource,
      template,
      schedule,
      monthlySoftQuota,
      monthlyHardQuota,
      quotaThreshold,
      recipientEmail,
      authorization,
      authorizationConfigs,
      republishExpired,
      readJsonLdSchema,
      disableUpdates,
    }

    setNewFeedData(newFeedObject)
  }, [
    schalttoolConfig,
    readJsonLdSchema,
    jassConfig,
    otherConfig,
    debugConfig,
    name,
    url,
    htmlSource,
    ignoreUrlSourceHttpErrors,
    ignoreZipSourceHttpErrors,
    zipSource,
    template,
    publisher,
    autoUnpublish,
    isActive,
    reader,
    readerConfig,
    schedule,
    monthlySoftQuota,
    monthlyHardQuota,
    quotaThreshold,
    airtableConfig,
    recipientEmail,
    authorization,
    authorizationConfigs,
    republishExpired,
    disableUpdates,
    jwExportConfig,
  ])

  return {
    dispatch,
    name,
    setName,
    url,
    setUrl,
    htmlSource,
    setHtmlSource,
    ignoreUrlSourceHttpErrors,
    setIgnoreUrlSourceHttpErrors,
    ignoreZipSourceHttpErrors,
    setIgnoreZipSourceHttpErrors,
    zipSource,
    setZipSource,
    template,
    setTemplate,
    publisher,
    setPublisher,
    defaultPublisher,
    setDefaultPublisher,
    publisherConfig,
    setPublisherConfig,
    autoUnpublish,
    setAutoUnpublish,
    isActive,
    setIsActive,
    reader,
    setReader,
    readerConfig,
    readersList,
    publishersList,
    uniqueId,
    serverResponse,
    setServerResponse,
    newFeedData,
    schedule,
    setSchedule,
    cronValue,
    setCronValue,
    monthlySoftQuota,
    setMonthlySoftQuota,
    monthlyHardQuota,
    setMonthlyHardQuota,
    quotaThreshold,
    setQuotaThreshold,
    recipientEmail,
    setRecipientEmail,
    authorization,
    setAuthorization,
    authorizationConfigs,
    setAuthorizationConfigs,
    republishExpired,
    setRepublishExpired,
    disableUpdates,
    setDisableUpdates,
    readJsonLdSchema,
    setReadJsonLdSchema,
  }
}
