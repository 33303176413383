import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useGetExportFeedsListQuery } from '../../api/api'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'
import { TexportFeedListItem } from '../../types/Tfeeds'
import ExportFeedItem from './item'

const ExporFeedList: React.FC = () => {
  const [exportFeeds, setExportFeeds] = useState<TexportFeedListItem[] | never[]>([])

  const { data: exportFeedsList, isError, isLoading, isSuccess } = useGetExportFeedsListQuery()

  useEffect(() => {
    isSuccess && setExportFeeds(exportFeedsList)
  }, [exportFeedsList, isSuccess])

  isError && <>Error: fetch import run list list from server</>

  return (
    <div className='FeedsList pageContainer'>
      <div className='buttonsContainer'>
        {localStorage.userRole !== 'view' && (
          <Link to='/add-new-export-feed' className='AddNewFeed'>
            <button className='buttonBase'>Add new +</button>
          </Link>
        )}
      </div>
      <div className='table'>
        <div className='tableHeader SingleFeedExport'>
          <ul>
            <li>Name</li>
            <li>Format</li>
            <li>Edit</li>
            <li>Options</li>
          </ul>
        </div>
        {isLoading && <LoadingSpinner />}
        {!!exportFeeds?.length &&
          exportFeeds.map((feed: TexportFeedListItem) => (
            <ExportFeedItem key={feed.id} name={feed.name} format={feed.format} id={feed.id} />
          ))}
      </div>
    </div>
  )
}

export default ExporFeedList
