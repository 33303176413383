import { useState } from 'react'
import { Link } from 'react-router-dom'

import EditButton from '../../components/atoms/buttons/editButt/EditButton'
import { DropDownContainer } from '../../components/organisms/dropdownContainer/DropDownContainer'

interface Iprops {
  id?: number
  name: string
  format: string
}

const ExportFeedItem: React.FC<Iprops> = ({ id, name, format }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false)
  const closeDropdown = () => {
    setDisplayDropdown(false)
  }

  return (
    <div className='SingleFeed row SingleFeedExport'>
      <ul className='active'>
        <li>{name}</li>
        <li>{format}</li>
        <li className='editButt'>
          <Link to={`/export-feeds/${Number(id)}`}>
            <EditButton />
          </Link>
        </li>
        <li className='advancedButt'>
          <div></div>
          <DropDownContainer
            closeDropdown={closeDropdown}
            setDisplayDropdown={setDisplayDropdown}
            displayDropdown={displayDropdown}
          >
            <DropdownOptions id={id} name={name} />
          </DropDownContainer>
        </li>
      </ul>
    </div>
  )
}

export default ExportFeedItem

interface Idropdown {
  id: number | undefined
  name: string
}

const DropdownOptions: React.FC<Idropdown> = ({ id }) => {
  return (
    <ul>
      <Link to={`/export-feed/exports/${id}`}>
        <li>Exports</li>
      </Link>
    </ul>
  )
}
