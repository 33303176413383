import { Link, useParams } from 'react-router-dom'

import { useGetTemplateHtmlContentQuery } from '../../../api/api'
import LoadingSpinner from '../../../components/atoms/loadingSpinner/LoadingSpinner'
import './style.scss'

const HtmlContent: React.FC = () => {
  const { name } = useParams()
  const { data, isLoading } = useGetTemplateHtmlContentQuery(name)

  if (isLoading) return <LoadingSpinner />

  return (
    <div className='HtmlContent pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/templates'>
            <p>templates</p>
          </Link>
        </span>
        <span> / </span>
        <span>{name}</span>
      </div>
      <h1>HTML content for template: {name}</h1>
      <pre className='content basicRadius'>{data.htmlContent}</pre>
    </div>
  )
}

export default HtmlContent
