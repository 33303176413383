import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { RootState } from '../../../redux/store'

const Header: React.FC = () => {
  const { user } = useSelector((state: RootState) => state)
  return (
    <div className='Header'>
      <Link to={'/'}>
        <img src={require('./logo.png')} alt='Logo' height='45px' />
      </Link>
      <ul className='navList'>
        <li>
          <Link to={'/feeds'}>
            <p>Feeds</p>
          </Link>
        </li>
        <li>
          <Link to={'/export-feeds'}>
            <p>Exports</p>
          </Link>
        </li>
        <li>
          <Link to={'/templates'}>
            <p>Templates</p>
          </Link>
        </li>
        {localStorage.userRole === 'admin' && (
          <li>
            <Link to={'/users'}>
              <p>Users</p>
            </Link>
          </li>
        )}
      </ul>
      <li className='userPanelIcon'>
        <Link to={'/panel'}>
          {user.isUserLoggedIn ? <p>{localStorage.username}</p> : <p>Login</p>}
        </Link>
      </li>
    </div>
  )
}

export default Header
