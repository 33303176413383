import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useAddFeedExportMutation } from '../../api/api'
import PreviewEdited from '../../components/organisms/preview/PreviewEdited'
import TextInput from '../../components/organisms/textInput/TextInput'
import { TexportFeedListItem } from '../../types/Tfeeds'
import { TresponseError } from '../../types/Tgeneral'
import { isExtraValidJson } from '../../util/tools/tools'
import ResponseResult from '../feedCRUD/editFeed/responseResult/ResponseResult'
import ZipSourceSelectBox from '../feedCRUD/sharedComponents/zipSourceSelectBox/ZipSourceSelectBox'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const uniqueId = 'exportFeedFormat-2lk3j4'

type TformatSource = 'json' | 'xml'

const AddNewExportFeed: React.FC = () => {
  const [name, setName] = useState('')
  const [format, setFormat] = useState<TformatSource>('xml')
  const [newFeedData, setNewFeedData] = useState<TexportFeedListItem>({})
  const [serverResponse, setServerResponse] = useState({})

  useEffect(() => {
    setNewFeedData({ name, format })
  }, [name, format])

  const unique = 'exportFeedFormat-2lk3j4'

  const [addFeed, { isError, error, isLoading, isSuccess, data: addFeedResponse }] =
    useAddFeedExportMutation()

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...error } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccess && notify('Added successfully!')
    isLoading && notify('Adding...', 700)
    addFeedResponse && setServerResponse(addFeedResponse)
    error && setServerResponse(error)
  }, [setServerResponse, error, isSuccess, isLoading, addFeedResponse])

  return (
    <div className='AddNewFeed pageContainer'>
      <h1>Add New Feed</h1>
      <form className='' onSubmit={handleSave}>
        <div className='greyRoundContainer'>
          <div className='inputContainer'>
            <TextInput
              label='Name'
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              uniqueId={uniqueId}
            />

            <div className={'SelectBox TemplateSelectBox'}>
              <label htmlFor={unique}>Format</label>
              <select
                value={format}
                onChange={(e) => setFormat(e.target.value as TformatSource)}
                id={unique}
                required={true}
              >
                <option value='json'>json</option>
                <option value='xml'>xml</option>
              </select>
            </div>
          </div>
        </div>

        <PreviewEdited editedData={newFeedData} />
        <div className='buttons'>
          <button className='SaveButt buttonBase' type='submit'>
            Save
          </button>
        </div>
      </form>
      {!!Object.keys(serverResponse).length && (
        <ResponseResult serverResponse={serverResponse} isError={isError} />
      )}
      {/* toConsider */}

      <ToastContainer limit={3} position='top-center' />
    </div>
  )

  async function handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!isExtraValidJson(notify)) {
      return
    }

    await addFeed({ newFeedData })
  }
}

export default AddNewExportFeed
