import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useAddTemplateMutation } from '../../../api/api'
import TextInput from '../../../components/organisms/textInput/TextInput'
import { TresponseError } from '../../../types/Tgeneral'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const AddNewTemplate: React.FC = () => {
  const uniqueId = '51866'
  const [name, setName] = useState('')
  const [zipFile, setZipFile] = useState<string | Blob>()

  const [addTemplate, { isLoading, isSuccess, data: response, error }] = useAddTemplateMutation()

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...error } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccess && notify('Updated successfully!')
    isLoading && notify('Adding...', 700)
    response && notify(response.message)
  }, [error, isSuccess, isLoading, response])

  //   async function handleChange(event: React.FormEvent<HTMLFormElement>) {
  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setZipFile(event.target.files[0])
    }
  }
  return (
    <div className='AddNewTemplate AddNewFeed pageContainer'>
      <h1>Add New Template</h1>
      <form className='' onSubmit={handleSave}>
        <div className='greyRoundContainer'>
          <div className='inputContainer'>
            <TextInput
              label='Name'
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              uniqueId={uniqueId}
            />
            <div className='zipInput'>
              <label htmlFor='zipFile'>zip file</label>
              <input
                type='file'
                className='custom-file-upload '
                accept='.zip,.rar,.7zip'
                required
                name={name}
                onChange={handleChange}
              />
            </div>
            <p className='hint'>
              * File type must be zip<br />
              * A HTML file with the name <pre>index.html</pre> must be in root<br />
              * Other files and directories are supported in the zip file
            </p>
          </div>
        </div>
        <div className='buttons'>
          <button className='SaveButt buttonBase' type='submit'>
            Save
          </button>
        </div>
      </form>
      <ToastContainer limit={3} position='top-center' />
    </div>
  )
  async function handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const formData = new FormData()

    zipFile && formData.append('zip', zipFile)
    const data = { zipFile: formData, templateName: name }
    await addTemplate(data)
  }
}

export default AddNewTemplate
