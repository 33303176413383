/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  TclientId,
  Texpires,
  TjwExportConfig,
  TpublisherConfig,
  TschalttoolConfig,
} from '../../../../../types/Tfeeds'
import { TfreeBundleJobBoards } from '../../../../../types/Tpublishers'
import { setJwExportConfig, setSchalttoolConfig } from '../../reduxStates'

const useAllHooks = (
  defaultPublisher: string,
  publisherConfig: TpublisherConfig,
  publisher: string,
) => {
  const dispatch = useDispatch()
  const [exportFeeds, setExportFeeds] = useState<string[]>([])
  //   const [defaultExportFeeds, setDefaultExportFeeds] = useState<string[]>([])

  useEffect(() => {
    if (defaultPublisher === 'jw-export' && publisher === 'jw-export') {
      setExportFeeds(publisherConfig.exportFeeds)
      //   setDefaultExportFeeds(publisherConfig.exportFeeds)
    }
  }, [defaultPublisher, publisherConfig, publisher])

  useEffect(() => {
    const newJwExportConfig: TjwExportConfig = {
      exportFeeds,
    }
    dispatch(setJwExportConfig(newJwExportConfig))
  }, [dispatch, exportFeeds])

  return {
    exportFeeds,
    setExportFeeds,
  }
}

export default useAllHooks
