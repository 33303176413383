import { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
// Import
import 'react-confirm-alert/src/react-confirm-alert.css'
// Import css
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

import { useSingleFeedAllExportRunsQuery, useTriggerExportRunMutation } from '../../../api/api'
import { DividerLine } from '../../../components/atoms/lines/DividerLine'
import LoadingSpinner from '../../../components/atoms/loadingSpinner/LoadingSpinner'
import { TresponseError } from '../../../types/Tgeneral'
import { IimportRun, SingleRun } from '../../feedsList/singleFeed/importRuns/components/SingleRun'
import { SingleExportRun } from './singleExportRun'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const ExportRuns: React.FC = () => {
  const { feedId } = useParams()
  const itemsPerPage = 60
  const [records, setrecords] = useState(itemsPerPage)

  const [importRuns, setImportRuns] = useState<[IimportRun]>()
  const [hasMore, setHasMore] = useState(true)

  const {
    data: exportRunList,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useSingleFeedAllExportRunsQuery(feedId)

  const [
    triggerExportRun,
    {
      data: triggerResponse,
      isLoading: isTriggering,
      error: triggerError,
      isSuccess: successTrigger,
    },
  ] = useTriggerExportRunMutation()

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...triggerError } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }

    triggerResponse && notify(triggerResponse.message)
  }, [error, isTriggering, successTrigger, triggerError, triggerResponse])

  useEffect(() => {
    isSuccess && setImportRuns(exportRunList)
  }, [exportRunList, isSuccess])

  if (isError) {
    return <h1>{JSON.stringify(error, null, 2)}</h1>
  }

  return (
    <div className='ImportRuns pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to={`/export-feeds/${feedId}`}>
            <p>Export Feeds / </p>
          </Link>
        </span>
        <span>{feedId}</span>
      </div>
      <h1>
        Export Runs for Export Feed<span>{feedId}</span>
      </h1>
      {localStorage.userRole !== 'view' && (
        <div className='triggers'>
          <div className='manualTrigger'>
            {isTriggering ? (
              <LoadingSpinner />
            ) : (
              <button className='buttonBase' onClick={submitTriggerNewRun}>
                Trigger manualy
              </button>
            )}
          </div>
        </div>
      )}

      <DividerLine />
      {!exportRunList?.length ? (
        <h1 className='emptyList'>No Export Runs for this feed</h1>
      ) : (
        <div className='table'>
          <div className='tableHeader'>
            <ul>
              <li>Id</li>
              <li>Status</li>
              <li>Start time</li>
              <li>Result</li>
            </ul>
          </div>
          <div className='rows'>
            {isLoading && <LoadingSpinner />}
            {importRuns && (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={<LoadingSpinner key={uuidv4()} />}
                useWindow={true}
              >
                <div className='tableContent'>{showItems(importRuns, records)}</div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
      <ToastContainer limit={3} position='top-center' />
    </div>
  )

  function loadMore() {
    if (importRuns && records >= importRuns?.length) {
      setHasMore(false)
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage)
      }, 200)
    }
  }

  function submitTriggerNewRun() {
    confirmAlert({
      title: 'Trigger an Export run',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => triggerExportRun(feedId),
        },
        {
          label: 'No',
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: 'overlay-custom-class-name',
    })
  }
}

function showItems(runsList: IimportRun[], records: number) {
  const items = []
  for (let i = 0; i < records; i++) {
    if (runsList[i]?.id) {
      items.push(
        <SingleExportRun
          key={runsList[i].id}
          id={runsList[i].id}
          status={runsList[i].status}
          startTime={runsList[i].createdAt || runsList[i].startTime}
          endTime={runsList[i].endTime}
          result={runsList[i].result}
          errorMessage={runsList[i].errorMessage}
        />,
      )
    }
  }
  return items
}

export default ExportRuns
