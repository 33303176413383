import { useGetExportFeedsListQuery } from '../../../../../api/api'
import useAllHooks from './hooksAndStates'

interface Iprops {
  publisher: string
}
export const JwExport: React.FC<Iprops> = ({ publisher }) => {
  const { exportFeeds, setExportFeeds } = useAllHooks(publisher)
  const { data: exportFeedsList } = useGetExportFeedsListQuery()

  if (publisher === 'jw-export') {
    return (
      <div className='publisherSelected '>
        <div className='SchalttoolSelected inputContainer'>
          <div className='checkBoxes'></div>
          <div></div>
          <h3 className='context'>Export Feeds</h3>
          <div></div>
          <div className=' inputContainer checkBoxes'>
            {exportFeedsList?.length &&
              exportFeedsList?.map((feed) => (
                <div key={feed.id} className='CheckBoxInput'>
                  <input
                    type='checkbox'
                    checked={exportFeeds?.includes(feed.name)}
                    onChange={() =>
                      setExportFeeds(
                        exportFeeds?.includes(feed.name)
                          ? exportFeeds?.filter((name) => name !== feed.name)
                          : [...exportFeeds, feed.name],
                      )
                    }
                  />
                  <label className='margin-left-10'>{feed.name}</label>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}
