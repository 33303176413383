import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { useEditExportFeedMutation, useGetFeedExportDetailsQuery } from '../../api/api'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'
import PreviewEdited from '../../components/organisms/preview/PreviewEdited'
import TextInput from '../../components/organisms/textInput/TextInput'
import { TexportFeedListItem } from '../../types/Tfeeds'
import { TresponseError } from '../../types/Tgeneral'
import ResponseResult from '../feedCRUD/editFeed/responseResult/ResponseResult'

const uniqueId = 'exportFeedFormat-2lk3j4'

type TformatSource = 'json' | 'xml'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const EditExportFeed: React.FC = () => {
  const [name, setName] = useState('')
  const [format, setFormat] = useState<TformatSource>('xml')
  const [editedData, setEditedData] = useState<TexportFeedListItem>({})
  const [serverResponse, setServerResponse] = useState({})

  const unique = 'exportFeedFormat-2lk3adshgj4'

  const { exportFeedId } = useParams()

  const handelReset = () => {
    setName(initialValues?.name || '')
    setFormat(initialValues?.format || 'xml')
  }

  useEffect(() => {
    setEditedData({ name, format })
  }, [name, format])

  const [
    editExportFeed,
    {
      isLoading: isUpdating,
      isError: isErrorMutation,
      error: errorFeedMutation,
      isSuccess,
      data: updateResponse,
    },
  ] = useEditExportFeedMutation()
  const handleSave = (e: React.FormEvent) => {
    e.preventDefault()
    editExportFeed({ exportFeedId, editedData })
  }

  const { data: initialValues, isLoading } = useGetFeedExportDetailsQuery(exportFeedId)

  useEffect(() => {
    if (initialValues) {
      setName(initialValues.name)
      setFormat(initialValues.format)
    }
  }, [initialValues])

  useEffect(() => {
    if (errorFeedMutation) {
      const errorObject: TresponseError = { ...errorFeedMutation } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccess && notify('Updated successfully!')
    isUpdating && notify('Updating...', 700)
    updateResponse && setServerResponse(updateResponse)
    errorFeedMutation && setServerResponse(errorFeedMutation)
  }, [setServerResponse, errorFeedMutation, isSuccess, isUpdating, updateResponse])

  if (isLoading) return <LoadingSpinner />

  return (
    <div className='EditFeed pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>export-feeds / </p>
          </Link>
        </span>
        <span>
          {name} - {exportFeedId}
        </span>
      </div>
      <h1>{name}</h1>
      <form className='' onSubmit={handleSave} onReset={handelReset}>
        <div className='greyRoundContainer'>
          <div className='inputContainer'>
            <TextInput
              label='Name'
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              uniqueId={uniqueId}
            />
            <div className={'SelectBox TemplateSelectBox'}>
              <label htmlFor={unique}>Format</label>
              <select
                value={format}
                onChange={(e) => setFormat(e.target.value as TformatSource)}
                id={unique}
                required={true}
              >
                <option value='json'>json</option>
                <option value='xml'>xml</option>
              </select>
            </div>
          </div>
        </div>
        <PreviewEdited editedData={editedData} />
        <div className='buttons'>
          <button className='CancelButt buttonBase' type='reset'>
            Revert
          </button>
          <button className='SaveButt buttonBase' type='submit' disabled={isUpdating || isLoading}>
            Save
          </button>
        </div>
      </form>
      {!!Object.keys(serverResponse).length && (
        <ResponseResult serverResponse={serverResponse} isError={isErrorMutation} />
      )}
      <ToastContainer limit={3} position='top-center' />
    </div>
  )
}

export default EditExportFeed
