import React, { useState } from 'react'

import { DividerLine } from '../../atoms/lines/DividerLine'

interface Iprops {
  html: string
}

const HtmlCodeView: React.FC<Iprops> = ({ html }) => {
  const [show, setShow] = useState(false)
  function handleOpen() {
    setShow(!show)
  }

  return (
    <div className='PreviewEdited'>
      <div className='container' onClick={handleOpen}>
        HTML preview: {show ? 'hide' : 'show'}
      </div>
      {show && (
        <pre>
          <DividerLine></DividerLine>
          {html}
        </pre>
      )}
    </div>
  )
}

export default HtmlCodeView
