import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { TjwExportConfig } from '../../../../../types/Tfeeds'
import { setJwExportConfig } from '../../../editFeed/reduxStates'

const useAllHooks = (publisher: string) => {
  const [exportFeeds, setExportFeeds] = useState<string[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (publisher === 'jw-export') {
      const jwExport: TjwExportConfig = {
        exportFeeds,
      }

      dispatch(setJwExportConfig(jwExport))
    }
  }, [dispatch, exportFeeds, publisher])

  return {
    exportFeeds,
    setExportFeeds,
  }
}

export default useAllHooks
